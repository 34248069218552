<template>
  <ViewsLoadingView
    :is-visible="
      !$msalBrowser.getters.isAuthenticated || !tenantsStore.state.inited
    "
  />
  <div v-if="$msalBrowser.getters.isAuthenticated" :class="$style.layout">
    <SharedTheHeader :class="$style.layout__header" />
    <div
      v-if="tenantsStore.state.inited"
      id="scrollable"
      :class="$style.layout__body"
    >
      <div
        :class="[
          $style.layout__content,
          (lightgray || $route.meta.lightgray) &&
            $style.layout__content_lightgray,
        ]"
      >
        <slot />
      </div>
      <SharedTheFooter :class="$style.layout__footer" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent } from '#imports'
import { useTenantsStore } from '~/store/tenants'

defineComponent({
  name: 'DefaultLayout',
})

defineProps<{ lightgray?: boolean }>()

const tenantsStore = useTenantsStore()
</script>

<style lang="scss" module>
.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__header {
    flex: 0 0 auto;
  }

  &__footer {
    flex: 0 0 auto;
  }

  &__body {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: auto;
    scroll-behavior: smooth;
    scroll-padding-top: 20px;
  }

  &__content {
    flex: 1 1 auto;

    &_lightgray {
      background: #fafafa;
    }
  }
}
</style>
