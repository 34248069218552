<template>
  <div ref="rootRef" :class="$style.profile">
    <button type="button" :class="$style.button" @click="onClick">
      {{ personalStore.getters.userInitials }}
    </button>

    <UiBaseDropdown
      :is-opened="dropdown.state.isOpened"
      :style="{ zIndex: dropdown.state.zIndex }"
      :class="$style.profile__dropdown"
    >
      <UiBaseDropdownOption :text="$t('nav.settings')" @click="openSettings" />
      <UiBaseDropdownOption :text="$t('buttons.logout')" @click="logout" />
    </UiBaseDropdown>
  </div>
</template>

<script lang="ts" setup>
import { ref, useRouter, useNuxtApp } from '#imports'
import { useOpened } from '~/composables/useOpened'
import { useMediaBreakpoints } from '~/composables/useBreakpoints'
import { usePersonalStore } from '~/store/personal'

const emit = defineEmits<{ (e: 'click'): void }>()

const { $msalBrowser } = useNuxtApp()

const personalStore = usePersonalStore()

const media = useMediaBreakpoints()

const router = useRouter()

const rootRef = ref<HTMLElement>()

const dropdown = useOpened({
  preventNodes: () => [rootRef.value],
})

const logout = (): void => {
  dropdown.actions.close()
  $msalBrowser.actions.logout()
}

const openSettings = (): void => {
  dropdown.actions.close()
  router.push('/settings/')
}

const onClick = (): void => {
  if (media.md.matches) {
    emit('click')
  } else {
    dropdown.actions.toggle()
  }
}
</script>

<style lang="scss" module>
.button {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 800;
  font-size: 11px;
  line-height: normal;
  text-align: center;
  vertical-align: top;
  background: #d2e3f8;
}

.profile {
  position: relative;

  &__dropdown {
    top: calc(100% + 5px);
    right: 0;
    width: 185px;
    max-height: 300px;
    overflow-x: hidden;
  }
}
</style>
