<template>
  <div
    v-if="sharedStore.state.subscriptionApplicationId"
    ref="rootRef"
    :class="$style.select"
  >
    <button
      type="button"
      :class="$style.select__control"
      @click="dropdown.actions.toggle"
    >
      <span :class="$style.select__controlText">
        <span
          :title="tenantsStore.getters.selectedTenant?.name || undefined"
          :class="$style.select__controlName"
        >
          {{ tenantsStore.getters.selectedTenant?.name ?? '...' }}
        </span>
        <span :class="$style.select__controlDivider" />
        <span
          :title="tenantsStore.getters.selectedSubscription?.name || undefined"
          :class="$style.select__controlName"
        >
          {{ tenantsStore.getters.selectedSubscription?.name ?? '...' }}
        </span>
      </span>
      <UiBaseIcon name="dots-vertical" :class="$style.select__arrow" />
    </button>
    <UiBaseDropdown
      :is-opened="dropdown.state.isOpened"
      :style="{ zIndex: dropdown.state.zIndex }"
      :class="$style.select__dropdown"
    >
      <Transition
        mode="out-in"
        :enter-from-class="
          isInner ? $style.select__list_right : $style.select__list_left
        "
        :enter-active-class="$style.select__list_active"
        :leave-to-class="
          isInner ? $style.select__list_left : $style.select__list_right
        "
        :leave-active-class="$style.select__list_active"
      >
        <div v-if="!isInner" :class="$style.select__list">
          <button
            v-for="(item, index) in tenantsStore.state.tenants"
            type="button"
            :key="item.id"
            :class="[
              $style.button,
              item.id === sharedStore.state.tenantId && $style.button_selected,
            ]"
            :title="item.name || undefined"
            @click="openOrganization(index)"
          >
            <span :class="$style.button__text">
              {{ item.name }}
            </span>
            <UiBaseIcon name="chevron-right" :class="$style.button__icon" />
          </button>
        </div>
        <div v-else :class="$style.select__list">
          <button type="button" :class="$style.button" @click="isInner = false">
            <UiBaseIcon name="chevron-left" :class="$style.button__icon" />
            <span :class="$style.button__text">
              {{ $t('buttons.back') }}
            </span>
          </button>
          <button
            v-for="(item, index) in tenantsStore.state.tenants[
              openedOrganization
            ].subscriptions"
            type="button"
            :key="item.id"
            :class="[
              $style.button,
              item.id === sharedStore.state.subscriptionId &&
                $style.button_selected,
            ]"
            :title="item.name || undefined"
            @click="selectSubscription(index)"
          >
            <span :class="$style.button__text">
              {{ item.name }}
            </span>
            <UiBaseIcon
              v-if="item.id === sharedStore.state.subscriptionId"
              name="check"
              :class="$style.button__icon"
            />
          </button>
        </div>
      </Transition>
    </UiBaseDropdown>
  </div>
</template>

<script lang="ts" setup>
import { ref, useRouter } from '#imports'
import { useOpened } from '~/composables/useOpened'
import { useTenantsStore } from '~/store/tenants'
import { useSharedStore } from '~/store/shared'

const router = useRouter()

const tenantsStore = useTenantsStore()

const sharedStore = useSharedStore()

const isInner = ref(false)

const openedOrganization = ref<number>(-1)

const openOrganization = (index: number): void => {
  openedOrganization.value = index
  isInner.value = true
}

const selectSubscription = (index: number): void => {
  const tenantId = tenantsStore.state.tenants[openedOrganization.value].id || ''
  const subscriptionId =
    tenantsStore.state.tenants[openedOrganization.value].subscriptions?.[index]
      ?.id || ''

  tenantsStore.actions.select(tenantId, subscriptionId)
  dropdown.actions.close()

  router.push('/')
}

const rootRef = ref<HTMLElement>()

const dropdown = useOpened({
  preventNodes: () => [rootRef.value],
  onClose() {
    isInner.value = false
    openedOrganization.value = -1
  },
})
</script>

<style lang="scss" module>
.select {
  position: relative;

  &__control {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    height: 25px;
    padding: 0 10px;
    border-radius: 12px;
    color: var(--accent-color);
    font-weight: 500;
    font-size: 10px;
    line-height: normal;
    background: rgb(var(--accent-color-rgb) / 0.1);
    transition: color 0.3s, background-color 0.3s;

    @include media($to: md) {
      width: 100%;
      height: 37px;
      border-radius: 19px;
      font-size: 14px;
    }
  }

  &__controlText {
    display: grid;
    flex: 1 1 auto;
    grid-template-columns: auto 2.5ch auto;
    min-width: 0;
  }

  &__controlName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__controlDivider {
    padding: 0 0.75ch;

    &:before {
      content: '/';
    }
  }

  &__arrow {
    flex: 0 0 auto;

    @include media($from: md) {
      margin-left: 5px;
      font-size: 12px;
    }

    @include media($to: md) {
      margin-left: 8px;
      font-size: 16px;
    }
  }

  &__dropdown {
    top: calc(100% + 5px);
    max-height: 300px;
    overflow-x: hidden;

    @include media($from: md) {
      --size: 185px;
      left: 50%;
      width: var(--size);
      min-width: 100%;
      transform: translateX(-50%);
    }

    @include media($to: md) {
      width: 100%;
      inset-inline: 0;
    }
  }

  &__list {
    margin-inline: -20px;

    &_left,
    &_right {
      opacity: 0;
    }

    &_left {
      transform: translateX(-20px);
    }

    &_right {
      transform: translateX(20px);
    }

    &_active {
      transition: opacity 0.3s, transform 0.3s;
    }
  }
}

.button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  color: var(--text-default);
  text-align: left;
  transition: color 0.3s, background-color 0.3s;
  padding-inline: 32px;

  &_selected {
    color: #fff;
    background: var(--accent-color);
  }

  &:hover,
  &:focus-visible {
    color: var(--text-default);
    background: rgb(var(--accent-color-rgb) / 0.1);
  }

  &__text {
    flex: 1 1 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__icon {
    flex: 0 0 auto;
    font-size: 20px;

    &:first-child {
      margin-right: 6px;
    }

    &:last-child {
      margin-left: 6px;
    }
  }
}
</style>
