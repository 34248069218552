<template>
  <header :class="$style.header">
    <div :class="$style.header__container">
      <NuxtLink to="/" :aria-label="$t('nav.dashboard')" :class="$style.logo">
        <UiBaseLogo
          :class="$style.logo__image"
          :layout="media.md.matches ? 'compact' : undefined"
        />
      </NuxtLink>
      <Teleport
        v-if="isMounted"
        to="#drawer-team"
        :disabled="!media.md.matches"
      >
        <SharedTheHeaderPartialsOrganizationControl
          :class="$style.header__organization"
        />
      </Teleport>
      <div :class="$style.header__actions">
        <SharedTheHeaderPartialsActionButton
          v-if="!media.md.matches"
          icon="book"
          tag="a"
          target="_blank"
          :aria-label="$t('buttons.readDocumentation')"
          :href="$externalLinks.docs"
        />
        <SharedTheHeaderPartialsProfileButton
          @click="onProfileClick"
          v-if="$msalBrowser.getters.isAuthenticated"
        />
        <NuxtLink to="/login" v-else>Login</NuxtLink>
      </div>
    </div>
    <Teleport v-if="isMounted" to="#drawer-nav" :disabled="!media.md.matches">
      <SharedTheHeaderPartialsMainNavigation @change="drawer.actions.close" />
    </Teleport>
    <div
      v-show="media.md.matches"
      :style="{ zIndex: drawer.state.zIndex }"
      :class="[$style.drawer, drawer.state.isOpened && $style.isOpened]"
    >
      <div id="drawer" ref="drawerRef" :class="$style.drawer__container">
        <button
          type="button"
          :class="$style.drawer__close"
          @click="drawer.actions.close"
        >
          <UiBaseIcon name="close" :class="$style.drawer__icon" />
        </button>
        <div :class="$style.drawer__inner">
          <div id="drawer-nav" :class="$style.drawer__nav" />
          <div id="drawer-team" :class="$style.drawer__team" />
          <div :class="$style.drawer__logout">
            <button
              type="button"
              :class="$style.logout"
              @click="$msalBrowser.actions.logout"
            >
              {{ $t('buttons.logout') }}
            </button>
          </div>
        </div>
        <div :class="$style.drawer__actions">
          <SharedTheHeaderPartialsActionButton
            v-if="false"
            icon="bell"
            :class="$style.drawer__action"
          >
            <span :class="$style.counter">2</span>
          </SharedTheHeaderPartialsActionButton>
          <SharedTheHeaderPartialsActionButton
            icon="book"
            tag="a"
            target="_blank"
            :aria-label="$t('buttons.readDocumentation')"
            :href="$externalLinks.docs"
            :class="$style.drawer__action"
          />
          <SharedTheHeaderPartialsActionButton
            v-if="false"
            icon="moon"
            :class="$style.drawer__action"
          />
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { ref } from '#imports'
import { useMediaBreakpoints } from '~/composables/useBreakpoints'
import { useIsMounted } from '~/composables/useIsMounted'
import { useOpened } from '~/composables/useOpened'

const drawerRef = ref<HTMLElement>()

const media = useMediaBreakpoints()

const drawer = useOpened({ preventNodes: () => [drawerRef.value] })

const isMounted = useIsMounted()

const onProfileClick = (): void => {
  if (media.md.matches) drawer.actions.open()
}
</script>

<style lang="scss" module>
.header {
  padding: 26px 30px 0;
  border-bottom: 1px solid #eaeaea;
  background: #ffffff;

  @include media($to: md) {
    padding: 10px 20px;
  }

  &__container {
    display: flex;
    align-items: center;
    max-width: var(--container-width);
    margin-inline: auto;

    @include media($from: md) {
      padding-bottom: 6px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: auto;

    > *:not(:first-child) {
      margin-left: 15px;

      @include media($to: md) {
        margin-left: 26px;
      }
    }
  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 24px;
    height: 24px;
    margin-left: auto;
    padding: 0 3px;

    &:before,
    &:after {
      content: '';
    }

    &:before,
    &:after,
    span {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: var(--text-default);
    }
  }
}

.counter {
  position: absolute;
  top: -10px;
  right: -6px;
  min-width: 19px;
  padding: 2px 4px;
  border-radius: 20px;
  color: #ffffff;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  background: #eb6c64;
}

.logo {
  display: inline-block;
  vertical-align: top;
  container-type: inline-size;

  @include media($from: md) {
    width: 210px;
    margin-right: 20px;
  }

  @include media($to: md) {
    width: 34px;
  }
}

.drawer {
  position: fixed;
  background: rgb(0 0 0 /0.5);
  transition: visibility 0.3s, opacity 0.3s;
  inset: 0;

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 325px;
    height: 100%;
    margin-left: auto;
    padding-top: 60px;
    background: #fff;
    transition: transform 0.3s;
  }

  &__inner {
    flex: 1 1 auto;
    overflow: auto;
  }

  &__nav {
    margin-bottom: 20px;
  }

  &__team {
    margin-bottom: 40px;
    padding: 0 20px;
  }

  &__logout {
    text-align: center;
  }

  &__actions {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }

  &__action {
    margin: 0 30px;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1em;
    height: 1em;
    color: var(--text-default);
    font-size: 24px;
  }

  &:not(.isOpened) {
    visibility: hidden;
    opacity: 0;

    .drawer__container {
      transform: translateX(100%);
    }
  }
}

.logout {
  height: 17px;
  border: 0;
  color: var(--text-default);
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  background: none;
}
</style>
