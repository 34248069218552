<template>
  <nav :class="$style.nav">
    <NuxtLink
      v-for="link in items"
      :key="link.text"
      :to="link.to"
      :exact-active-class="$style.nav__link_exactActive"
      :active-class="$style.nav__link_active"
      :class="$style.nav__link"
      @click="$emit('change')"
    >
      {{ link.text }}
    </NuxtLink>
  </nav>
</template>

<script lang="ts" setup>
import { computed, useNuxtApp } from '#imports'
import { useI18n } from 'vue-i18n'

defineEmits<{ (e: 'change'): void }>()

const { $accessManager } = useNuxtApp()

const { t } = useI18n()

const items = computed(() => {
  const items: { to: string; text: string }[] = []

  if ($accessManager.read.dashboard)
    items.push({
      to: '/',
      text: t('nav.dashboard'),
    })

  if ($accessManager.read.sites)
    items.push({
      to: '/sites/',
      text: t('nav.sites'),
    })

  if ($accessManager.read.providers)
    items.push({
      to: '/providers/',
      text: t('nav.providers'),
    })

  if ($accessManager.read.themes)
    items.push({
      to: '/themes/',
      text: t('nav.themes'),
    })

  if ($accessManager.read.apiKeys || $accessManager.read.users)
    items.push({
      to: '/settings/',
      text: t('nav.settings'),
    })

  return items
})
</script>

<style lang="scss" module>
.nav {
  max-width: var(--container-width);
  margin-inline: auto;

  @include media($from: md) {
    display: flex;
    align-items: flex-end;
  }

  @include media($to: md) {
    padding: 20px;
  }

  &__link {
    position: relative;
    display: block;
    font-size: 14px;
    line-height: 17px;

    @include media($from: md) {
      padding: 12px 6px 13px;
      color: var(--text-inactive);
    }

    @include media($to: md) {
      padding: 10px;
      border-bottom: 1px solid #eaeaea;
      color: var(--text-default);
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

    &:not(:last-child) {
      @include media($from: md) {
        margin-right: 24px;
      }

      @include media($to: md) {
        margin-bottom: 10px;
      }
    }

    &:after {
      position: absolute;
      opacity: 0;
      transition: opacity 0.3s;
      content: '';

      @include media($from: md) {
        border-bottom: 2px solid var(--text-default);
        inset: auto 0 0;
      }

      @include media($to: md) {
        display: none;
      }
    }

    &_active,
    &_exactActive {
      color: var(--text-default);

      @include media($from: md) {
        font-weight: 600;
      }

      @include media($to: md) {
        font-weight: 800;
      }

      &:after {
        opacity: 1;
      }
    }
  }
}
</style>
